import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ContactForm from "../../components/contactForm"
import Hero from "../../components/hero"
import Services from "../../components/services2"
import Content from "../../components/content"

/**
 * GraphQL Query to retrieve page data
 *
 * @return {Object}
 */
export const ServicesPageQuery = graphql`
  {
    site {
      siteMetadata {
        email
        phone
      }
    }
    hero: file(relativePath: { eq: "pages/about/header-home-erick.jpg" }) {
      sharp: childImageSharp {
        fluid(
          quality: 100
          maxWidth: 1920
          duotone: { highlight: "#15181f", shadow: "#15181f", opacity: 55 }
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

/**
 * Services Page Component
 * This component is used to generate the Services page
 *
 * @param {Object} props
 */
const ReposicionPartidasPage = ({ data }) => {
  return (
    <Layout isSecondary={true}>
      <SEO title="Asientos Extemporaneos" description="" />

      <Hero
        type="simple"
        tagline="Estos son los principales servicios jurídicos que le ofrecemos"
        title="Asientos Extemporáneos"
        background={data.hero.sharp.fluid}
      />

      <Content
        tagline=""
        title="Asientos Extemporáneos"
      >
        <p>
        El fundamento legal lo encontramos el artículo 27 Bis del <strong>ACUERDO DEL DIRECTORIO NÚMERO 176-2008 </strong>
        que se refiere a <strong>REGLAMENTO DE INSCRIPCIONES DEL REGISTRO CIVIL DE LAS PERSONAS </strong> de la República
        de Guatemala.
        </p>        
        <p>
        Las inscripciones de hechos y actos relativos al estado civil y capacidad civil de las personas
        naturales, que se realicen después de treinta días de acaecidos dichos actos o hechos se
        considerarán extemporáneas.
          </p>         
      </Content>

      <Services />

      <ContactForm
        email={data.site.siteMetadata.email}
        phone={data.site.siteMetadata.phone}
      />
    </Layout>
  )
}

export default ReposicionPartidasPage

